import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { type CSSProperties, type FC } from 'react';

import { LinkButton } from '@/components/buttons/link-button';
import { FiizyButton } from '@/components/calculators/fiizy/elements/fiizy-button';

export interface RelatedFirmsProps {
    render: GraphqlSelect.Firm<'name' | 'image' | 'uri'>[];
    fiizy?: boolean;
}

const allFirmsStyle: CSSProperties = {
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    marginTop: 'clamp(24px, 3.1vw, 40px)',
};

export const RelatedFirms: FC<RelatedFirmsProps> = ({ render, fiizy }) => {
    return (
        <div style={allFirmsStyle} className="d-grid g-32">
            {render.map((firm, id) => (
                <div key={id} style={{ borderRadius: '8px' }} className="bg-gray border-primary relative">
                    {firm.image?.remoteFile && (
                        <div style={{ borderRadius: '0' }} className="box-logotype">
                            <GatsbyImage image={getImage(firm.image.remoteFile)!} alt="" />
                        </div>
                    )}

                    <div className="box">
                        <Link to={firm.uri.url} style={{ marginBottom: '10px' }} className="full-link fs-24 d-block">
                            {firm.name}
                        </Link>

                        {fiizy ? (
                            <FiizyButton className="relative" style={{ zIndex: 2 }} />
                        ) : (
                            <LinkButton to={firm.uri.url} className="relative" style={{ zIndex: 2 }}>
                                Ofertă partener
                            </LinkButton>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export const query = graphql`
    fragment RelatedFirms on Firm {
        ...FirmImage
        name

        uri {
            url
        }
    }
`;
