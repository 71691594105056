import { StaticImage } from 'gatsby-plugin-image';
import React, { type CSSProperties, type FC } from 'react';

import { Html } from '@/components/content/html';

export type CompanyPartnerProps = GraphqlSelect.Partners<'becomePartner'>;

const imageColumnStyle: CSSProperties = {
    flex: '0 0 fit-content',
    margin: '0 auto',
};

const contentColumnStyle: CSSProperties = {
    maxWidth: '700px',
    padding: '20px 15px',
};

export const CompanyPartner: FC<CompanyPartnerProps> = ({ becomePartner }) => {
    return (
        <section
            style={{ minHeight: '602px' }}
            className="box become-partner relative bg-dark c-white d-flex flex-column jc-center p-0 mt-72"
        >
            <div style={imageColumnStyle} className="partner-person">
                <StaticImage
                    quality={90}
                    width={752}
                    height={602}
                    src="../../../../static/assets/partners/tymon.png"
                    alt=""
                />
            </div>

            <div className="container">
                <div style={contentColumnStyle} className="relative bg-dark pl-xl-0">
                    <h2 className="fs-48">{becomePartner.title}</h2>
                    <Html style={{ marginTop: '20px' }} className="fs-20">
                        {becomePartner.description.html}
                    </Html>

                    {(becomePartner.email || becomePartner.phone) && (
                        <div style={{ gap: '20px 10px', marginTop: '20px' }} className="d-flex flex-wrap">
                            {becomePartner.email && (
                                <a
                                    style={{ flex: '1 1 200px' }}
                                    href={`mailto:${becomePartner.email}`}
                                    className="button blue-button"
                                >
                                    {becomePartner.email}
                                </a>
                            )}

                            {becomePartner.phone && (
                                <a
                                    style={{ flex: '1 1 200px' }}
                                    href={`tel:${becomePartner.phone.replaceAll(/s/gi, '')}`}
                                    className="button blue-button"
                                >
                                    {becomePartner.phone}
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
