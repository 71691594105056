import React, { type FC } from 'react';

import { Html } from '@/components/content/html';
import { RelatedFirms } from '@/components/related/related-firms';

export type OurPartnersProps = GraphqlSelect.Partners<'partnersCopy' | 'partnersList'>;

export const OurPartners: FC<OurPartnersProps> = ({ partnersCopy, partnersList }) => {
    return (
        <section className="container mt-72">
            <Html className="fs-18">
                {`<h2 class="fs-64">${partnersCopy.title}</h2>` + partnersCopy.description.html}
            </Html>

            {partnersList.length > 0 && <RelatedFirms render={partnersList} />}
        </section>
    );
};
